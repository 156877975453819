import { bleskHeader } from '@default/scripts/modules/blesk-header';

bleskHeader();

/**
 * Modul ipadChangeViewport zajistuje na iPadu upravu meta tagu viewport a pridani provizornich CSS
 *
 * Dynamicky import modulu, pokud je detekován iPad
 */
const dynamicIpadChangeViewport = async () => {
    if (document.documentElement.clientWidth <= 1024 && navigator.userAgent.match(/iPad/i)) {
        const { ipadChangeViewport } = await import('./modules/ipad-change-viewport');

        ipadChangeViewport();
    }
};

/**
 * Anketa s přihlášením pro zobrazení výsledku.
 */
const dynamicEnquiry = async () => {
    const enquiryElements = document.querySelectorAll('.enquiry-bars');

    if (enquiryElements && enquiryElements.length) {
        const { enquiryBars } = await import(
            /* webpackChunkName: 'chunk-enquiry-bars' */
            '@default/scripts/modules/enquiryBars/enquiryBars'
        );

        enquiryBars(enquiryElements);
    }
};

/** Spusteni dynamickych importu */
const loadDynamicImports = () => {
    dynamicIpadChangeViewport();
    dynamicEnquiry();
};

loadDynamicImports();
